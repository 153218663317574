var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "tab-interval": "36pt", padding: "0 8px 8px" } },
    [
      _c(
        "div",
        {
          staticClass: "Section0",
          staticStyle: { "layout-grid": "15.6000pt" },
        },
        [
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "7.8000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "center",
                background: "rgb(255,255,255)",
              },
              attrs: { align: "center" },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "15.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("儿童个人信息保护规则及监护人须知"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "right",
              },
              attrs: { align: "right" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("发布日期："),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "right",
              },
              attrs: { align: "right" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("本版生效日期："),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "justify",
                "text-justify": "inter-ideograph",
              },
              attrs: { align: "justify" },
            },
            [
              _c("span", {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "等线",
                  color: "rgb(0,0,0)",
                  "letter-spacing": "0.0000pt",
                  "text-transform": "none",
                  "font-style": "normal",
                  "font-size": "10.5000pt",
                  "mso-font-kerning": "0.0000pt",
                },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("上海卫心科技有限"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("公司（以下统称"),
                  ]),
                  _vm._v("“"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("卫心")])],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "”或“我们”）深知儿童（指未满十四周岁的未成年人，下同）个人信息和隐私安全的重要性。我们希望通过《"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("儿童个人信息保护规则及监护人须知》（以下简称"),
                  ]),
                  _vm._v(
                    "“本政策”）说明我们在收集和使用儿童个人信息时对应的处理规则等相关事宜。"
                  ),
                ],
                1
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("在使用我们"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(0),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("的各项产品和服务（以下统称"),
                    ]),
                    _vm._v("“"),
                  ],
                  1
                ),
              ]),
              _vm._m(1),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("服务"),
                    ]),
                    _vm._v(
                      "”）前，请儿童、儿童的父母或其他监护人（以下统称“监护人”）务必仔细阅读并透彻理解本政策，特别是以粗体标识的条款应重点阅读，在确认充分理解并同意全部条款后再开始使用。"
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("监护人特别说明："),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "若我们获悉您的孩子不满十四周岁，我们将根据本政策采取特殊措施保护我们获得的您孩子的个人信息。请帮助我们保护您孩子的个人信息和隐私安全，要求他们在您的监护下共同阅读并接受本政策，且应在您的同意和指导下使用"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务、提交个人信息。如果您不同意本政策的内容，将可能导致"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务无法正常运行，或者无法达到我们拟达到的服务效果，您应要求您的孩子立即停止访问"
                    ),
                  ]),
                  _vm._v("/使用"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("服务。您于线上点击同意本政策，或者您的孩子使用"),
                  ]),
                  _vm._v("/继续使用"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务、提交个人信息，都表示您同意我们按照本政策（包括更新版本）收集、使用、存储、共享、转让和披露您孩子的个人信息。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("儿童特别说明："),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "我们建议：任何儿童参加网上活动都应事先取得监护人的同意。如果您是儿童，请通知您的监护人共同阅读本政策，并在您使用"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务、提交个人信息之前，寻求您的监护人的同意和指导。您于线上点击同意本政策，或者您使用"
                    ),
                  ]),
                  _vm._v("/继续使用"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务、提交个人信息，都表示您已获得您的监护人的许可，您的监护人均同意我们按照本政策（包括更新版本）收集、使用、存储、共享、转让和披露您的个人信息。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "本政策适用于我们在中华人民共和国境内通过网络从事收集、使用"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "font-weight": "normal",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("、")])],
                1
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "存储、共享、转让和披露儿童个人信息等活动。当儿童使用任何"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(2),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "服务时，监护人同意接受本政策以及我们在该单项服务中发出的特定儿童个人信息保护类规则条款（如有，以下称"
                      ),
                    ]),
                    _vm._v(
                      "“特定条款”）的保护，在此情况下特定条款与本政策条款同时对监护人和儿童产生效力。如特定条款与本政策条款存在同类条款的不一致约定，则在特定条款约束范围内应以特定条款为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。在儿童个人信息保护规则方面，本政策与"
                    ),
                  ],
                  1
                ),
              ]),
              _c("span", [
                _c(
                  "a",
                  { attrs: { href: "https://yixin.im/privacy/index.html" } },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          staticClass: "15",
                          staticStyle: {
                            "mso-spacerun": "'yes'",
                            "font-family": "微软雅黑",
                            color: "rgb(68,114,196)",
                            "letter-spacing": "0.0000pt",
                            "font-weight": "bold",
                            "text-transform": "none",
                            "font-style": "normal",
                            "font-size": "10.5000pt",
                            background: "rgb(255,255,255)",
                            "mso-shading": "rgb(255,255,255)",
                          },
                        },
                        [
                          _c("font", { attrs: { face: "微软雅黑" } }, [
                            _vm._v("《隐私政策》"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "正文存在不一致的，本政策优先适用，如本政策中未提及的，则以"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("span", [
                _c(
                  "a",
                  { attrs: { href: "https://yixin.im/privacy/index.html" } },
                  [
                    _c("b", [
                      _c(
                        "span",
                        {
                          staticClass: "15",
                          staticStyle: {
                            "mso-spacerun": "'yes'",
                            "font-family": "微软雅黑",
                            color: "rgb(68,114,196)",
                            "letter-spacing": "0.0000pt",
                            "font-weight": "bold",
                            "text-transform": "none",
                            "font-style": "normal",
                            "font-size": "10.5000pt",
                            background: "rgb(255,255,255)",
                            "mso-shading": "rgb(255,255,255)",
                          },
                        },
                        [
                          _c("font", { attrs: { face: "微软雅黑" } }, [
                            _vm._v("《隐私政策》"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("为准。"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "在阅读完本政策后，如对本政策或与本政策相关的事宜有任何问题，可通过"
                    ),
                  ]),
                ],
                1
              ),
              _c("span", [
                _c("a", { attrs: { href: "#seven" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "10.5000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("本政策"),
                        ]),
                        _vm._v("“如何联系我们”章节"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("所列的反馈渠道联系我们，我们会尽快作出解答。"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _vm._m(3),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("本政策将帮助您了解以下内容："),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", [
                _c("a", { attrs: { href: "#one" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "11.0000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("一、我们如何收集和使用儿童个人信息"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", [
                _c("a", { attrs: { href: "#two" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "11.0000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("二、我们可能共享、转让或披露的儿童个人信息"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", [
                _c("a", { attrs: { href: "#three" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "11.0000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("三、我们如何存储和保护儿童个人信息"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", [
                _c("a", { attrs: { href: "#four" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "11.0000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("四、如何管理儿童个人信息"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", [
                _c("a", { attrs: { href: "#five" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "11.0000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("五、第三方服务"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", [
                _c("a", { attrs: { href: "#six" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "11.0000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("六、本政策的修订"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", [
                _c("a", { attrs: { href: "#seven" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "11.0000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("七、如何联系我们"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._m(4),
              _vm._m(5),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("a", { attrs: { name: "one" } }),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("一、我们如何收集和使用儿童个人信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "（一）我们会严格履行法律法规规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，在征得监护人的同意后收集和使用儿童个人信息："
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("1、")]
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("在部分单项服务中，我们可能会要求用户填写个人"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("年龄"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("信息或根据用户填写的身份信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "，识别该用户是否为儿童，并按照法律法规的规定，在获得监护人同意之后才允许儿童继续使用相关"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("服务。"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "2、当我们识别出用户为儿童时，我们可能会收集监护人的联系方式（例如"
                  ),
                ]
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("手机号码、电子邮箱"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "），并联系监护人以验证其与儿童的监护关系。为了更好地保护儿童的权益，我们还可能向监护人收集更多信息（例如"
                    ),
                  ]),
                ],
                1
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("姓名、身份证、户口本或其他监护关系证明"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("），以便进一步验证监护人与儿童的监护关系。"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("3、在儿童使用")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务过程中，我们可能还会收集和使用其他儿童个人信息，具体请查阅"
                    ),
                  ]),
                ],
                1
              ),
              _c("span", [
                _c("a", { attrs: { href: "#one" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "10.5000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("《隐私政策》"),
                        ]),
                        _vm._v("“我们如何收集和使用个人信息”章节"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "进行详细了解。如我们需要超出上述范围收集和使用儿童个人信息，我们将再次征得监护人的同意。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（二）征得授权同意的例外"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "根据相关法律法规规定，以下情形中收集儿童的信息无需征得儿童和"
                    ),
                  ]),
                  _vm._v("/或监护人的授权同意："),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v(
                    "1）与国家安全、国防安全、公共安全、公共卫生、重大公共利益直接相关的；"
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v("2）与刑事侦查、起诉、审判和判决执行等直接相关的；"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v(
                    "3）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到监护人本人同意的；"
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v("4）所收集的信息是儿童或监护人自行向社会公众公开的；"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v(
                    "5）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；"
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v("6）根据监护人的要求签订合同所必需的；"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v("7）用于维护"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("服务的安全稳定运行所必需的，例如发现、处置"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("服务的故障；"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v(
                    "8）通过计算机信息系统自动留存处理信息且无法识别所留存处理的信息属于儿童个人信息的；"
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v(
                    "9）出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；"
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("（")]),
                  _vm._v("10）法律法规规定的其他情形。"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（三）有关敏感个人信息的提示"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "儿童的个人信息属于敏感个人信息。请监护人和儿童谨慎并留意儿童敏感个人信息，监护人同意儿童相关敏感个人信息我们可以按本政策所述的目的和方式来处理。"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("a", { attrs: { name: "two" } }),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("二、我们可能共享、转让或披露的儿童个人信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("（一）共享"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "除以下情形外，未经监护人的同意，我们不会与任何第三方共享儿童的个人信息："
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _vm._m(6),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("使用"),
                    ]),
                    _vm._v("PDBox"),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("平台"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("共享信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [_c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("的")])],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("其他机构"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "、提供数据服务（包括网络广告监测、数据统计、数据分析）的合作伙伴和其他服务提供商；"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _vm._m(7),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _vm._m(8),
              _c("span", [
                _c("a", { attrs: { href: "#one" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "10.5000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("第一条"),
                        ]),
                        _vm._v("“我们如何收集和使用儿童个人信息”"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("部分所述目的；"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _vm._m(9),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _vm._m(10),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、儿童、监护人或其他"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息；"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("7、应儿童合法需求或经监护人的授权同意；")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("8、应监护人合法要求；")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "9、根据单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("10、基于符合法律法规的社会公共利益而提供。")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "我们仅会出于合法、正当、必要、特定、明确的目的共享儿童个人信息。对我们与之共享个人信息的公司、组织和个人，我们会进行安全评估，并与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("（二）转让"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("1、")]
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而儿童的信息有可能作为此类交易的一部分而被转移"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "。我们会进行安全评估，并要求新的持有儿童个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向监护人征求授权同意。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "2、在获得监护人的同意后，我们会向其他方转让儿童个人信息。"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("（三）披露"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露儿童个人信息："
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "1、根据儿童或监护人的需求，在监护人同意的披露方式下披露其所指定的信息；"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供儿童信息的情况下，我们可能会依据所要求的信息类型和披露方式披露儿童的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "（四）共享、转让、披露信息时事先征得授权同意的例外"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "以下情形中，共享、转让、披露儿童的信息无需事先征得监护人的授权同意："
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("1、与国家安全、国防安全直接相关的；")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("2、与公共安全、公共卫生、重大公共利益")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "12.0000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("直接相"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("关的；"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "3、与刑事侦查、起诉、审判和判决执行等司法或行政执法直接相关的；"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "4、出于维护儿童或其他个人的生命、财产等重大合法权益但又很难得到监护人同意的；"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("5、儿童或监护人自行向社会公众公开的信息；")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("7、与我们履行法律法规规定的义务相关的。")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向监护人通知并征得监护人的同意。"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("a", { attrs: { name: "three" } }),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("三、我们如何存储和保护儿童个人信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "（一）除法律法规或监管部门另有规定外，我们仅在本政策所述目的所必需且最短时限内存储儿童个人信息"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "。如我们终止服务或运营，我们将及时停止继续收集儿童个人信息的活动，同时会遵守相关法律法规要求提前向监护人通知，并在终止服务或运营后对儿童的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "（二）我们非常重视儿童的隐私安全，成立了专门的安全团队，并采取一切合理可行的措施，保护儿童个人信息："
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "我们会采取加密等技术措施存储儿童个人信息，确保信息安全。同时，我们对工作人员以最小授权为原则，严格设定信息访问权限，控制儿童个人信息知悉范围；工作人员访问儿童个人信息的，须经过儿童个人信息保护负责人或者其授权的管理人员审批，记录访问情况，并采取技术措施，避免违法复制、下载儿童个人信息。如我们发现儿童个人信息发生或者可能发生泄露、毁损、丢失的，会立即启动应急预案，采取补救措施，并将事件相关情况以邮件、信函、电话、推送通知、公告等方式告知受影响的监护人和儿童。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("如您希望了解更多，请查阅"),
                  ]),
                ],
                1
              ),
              _c("span", [
                _c("a", { attrs: { href: "#four" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "10.5000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("《隐私政策》"),
                        ]),
                        _vm._v("“我们如何存储和保护个人信息”章节"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("，详细了解我们如何存储和保护儿童个人信息。"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。监护人需要了解，儿童接入"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(11),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("请监护人和儿童务必妥善保管好"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(12),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("服务帐号、密码及其他身份要素。儿童在使用"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(13),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "服务时，我们会通过帐号、密码及及其他身份要素来识别其身份。一旦监护人和儿童泄露了前述信息，可能会蒙受损失，并可能对自身产生不利影响。如监护人和儿童发现帐号、密码及"
                      ),
                    ]),
                    _vm._v(
                      "/或其他身份要素可能或已经泄露时，请立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。"
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("a", { attrs: { name: "four" } }),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("四、如何管理儿童个人信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("在儿童使用"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(14),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "服务期间，我们将在我们的单项服务中采取适当的操作设置、指引或提供"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("span", [
                _c("a", { attrs: { href: "#seven" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "10.5000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("本政策"),
                        ]),
                        _vm._v("“如何联系我们”章节"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "所列的联系渠道，尽可能保证监护人和儿童可以访问、更正、删除儿童个人信息，以及注销儿童注册帐号"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "。在访问、更正、删除前述信息或申请注销帐号时，我们可能会要求监护人和"
                    ),
                  ]),
                  _vm._v(
                    "/或儿童进行身份验证，以保障信息安全。除法律法规另有规定，当监护人和儿童更正、删除儿童个人信息或申请注销帐号时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。"
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（一）访问儿童个人信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("监护人和儿童可以查看其在使用"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务中提供或产生的儿童个人信息，例如个人信息资料、部分使用记录、发布内容。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（二）更正儿童个人信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "我们鼓励监护人和儿童更新和修改儿童个人信息以使其更准确有效。监护人和儿童发现我们收集、使用和处理的儿童个人信息有错误的，可以联系我们更正。我们会在完成身份验证和核实问题后，及时采取措施予以更正。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（三）删除儿童个人信息"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("根据儿童选择"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("服务的具体情况，监护人和儿童可以在使用"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务中自行删除部分儿童个人信息。在以下情形下，监护人和儿童可以直接向我们提出删除儿童个人信息的请求，我们会在完成身份验证和核实问题后及时采取措施予以删除，包括："
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "1、我们违反法律法规的规定或者与监护人和儿童的约定收集、存储、使用、转移、披露儿童个人信息的；"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _vm._v(
                    "2、超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息的；"
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("3、监护人撤回同意的；")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("4、监护人或儿童通过注销等方式终止使用")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("服务的。"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "但请注意，若监护人和儿童自行删除或请求我们删除特定的儿童个人信息，可能导致无法继续使用全部或部分"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(15),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("服务。"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（四）注销帐号"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "justify",
                "text-justify": "inter-ideograph",
              },
              attrs: { align: "justify" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _vm._v(
                    "1、我们提供账号注销功能，监护人和儿童可通过如下路径注销帐户："
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "justify",
                "text-justify": "inter-ideograph",
              },
              attrs: { align: "justify" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("电子邮件路径："),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("请")])],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("通过向"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("文末提供的"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("电子邮件地址发送电子邮件"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("联系我们，核实帐户信息后，我们将会在"),
                  ]),
                  _vm._v("5个工作日内为您完成销户。"),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "justify",
                "text-justify": "inter-ideograph",
              },
              attrs: { align: "justify" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "选择注销帐户后，该帐户内的所有信息将被清空，且不可恢复。我们将不会再收集、使用或共享与该帐户相关的个人信息。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "微软雅黑",
                  color: "rgb(0,0,0)",
                  "letter-spacing": "0.0000pt",
                  "text-transform": "none",
                  "font-style": "normal",
                  "font-size": "10.5000pt",
                  "mso-font-kerning": "0.0000pt",
                  background: "rgb(255,255,255)",
                  "mso-shading": "rgb(255,255,255)",
                },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（五）改变授权同意的范围"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "监护人和儿童总是可以选择是否披露信息。有些信息是使用"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务所必需的，但大多数其他信息的提供是自行决定的。监护人和儿童可以通过删除信息、关闭设备功能等方式改变其授权我们继续收集信息的范围或撤回其授权。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "当监护人和儿童撤回授权后，我们无法继续提供撤回授权所对应的服务，也不再处理相应的信息。但撤回授权的决定，不会影响此前基于监护人和儿童的授权而开展的信息处理。"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("（六）个人信息副本获取"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "font-weight": "normal",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "如需要获取儿童的个人信息的副本，监护人和儿童可以通过"
                    ),
                  ]),
                ],
                1
              ),
              _c("span", [
                _c("a", { attrs: { href: "#seven" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "10.5000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("本政策"),
                        ]),
                        _vm._v("“如何联系我们”章节"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "font-weight": "normal",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "所列的反馈渠道联系我们，在核实身份后，我们将提供儿童在我们的服务中的个人信息副本"
                    ),
                  ]),
                  _vm._v(
                    "(包括基本资料、身份信息)，但法律法规另有规定的或本政策另有约定的除外。"
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("a", { attrs: { name: "five" } }),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("五、第三方服务"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务可能为儿童接入或链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。请查阅"
                    ),
                  ]),
                ],
                1
              ),
              _c("span", [
                _c("a", { attrs: { href: "#six" } }, [
                  _c("b", [
                    _c(
                      "span",
                      {
                        staticClass: "15",
                        staticStyle: {
                          "mso-spacerun": "'yes'",
                          "font-family": "微软雅黑",
                          color: "rgb(68,114,196)",
                          "letter-spacing": "0.0000pt",
                          "font-weight": "bold",
                          "text-transform": "none",
                          "font-style": "normal",
                          "font-size": "10.5000pt",
                          background: "rgb(255,255,255)",
                          "mso-shading": "rgb(255,255,255)",
                        },
                      },
                      [
                        _c("font", { attrs: { face: "微软雅黑" } }, [
                          _vm._v("《隐私政策》"),
                        ]),
                        _vm._v("“第三方服务”章节"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("，详细了解我们接入或链接的第三方服务类型。"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "该等第三方社交媒体或其他服务由相关的第三方负责运营。儿童使用该等第三方的社交媒体服务或其他服务（包括儿童向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，监护人和儿童需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如监护人和儿童发现这些第三方社交媒体或其他服务存在风险时，建议终止相关操作以保护儿童的合法权益并及时与我们取得联系。"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("a", { attrs: { name: "six" } }),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("六、本政策的修订"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("为了提供更好的服务，我们可能会根据"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(16),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。如该等更新造成监护人和儿童在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过网站公告、推送通知、弹窗提示或其他方式来通知，监护人如果不同意该等变更，可以选择要求儿童停止使用"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._m(17),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("服务。"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "我们的任何修改都会将用户的满意度置于首位。我们鼓励监护人和儿童在每次使用"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "服务时都查阅我们的《儿童个人信息保护规则及监护人须知》。"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "h2",
            {
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("a", { attrs: { name: "seven" } }),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "12.0000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("七、如何联系我们"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "18.0000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [_c("o:p")],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "0.0000pt",
                "mso-pagination": "widow-orphan",
                "text-align": "justify",
                "text-justify": "inter-ideograph",
              },
              attrs: { align: "justify" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "我们设有个人信息保护专职部门并指定了专门的儿童个人信息保护负责人，将严格按照本政策保护儿童个人信息。如监护人和儿童有关于网络信息安全的投诉和举报，或对本政策、"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_vm._v("PDBox平台")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "的儿童个人信息保护规则、措施的相关事宜有任何问题、意见或建议，"
                    ),
                  ]),
                ],
                1
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("请随时与我们联系，"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "请与我司个人信息保护负责人联系，【在线问题反馈路径】："
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "Calibri",
                    "mso-fareast-font-family": "宋体",
                    "mso-bidi-font-family": "'Times New Roman'",
                    "font-size": "12.0000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_vm._v("http://www.ivisense.com/about/contact/")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("；【")])],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("上海卫心"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v("科技有限公司注册地址】："),
                  ]),
                ],
                1
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v("上海市静安区寿阳路"),
                    ]),
                    _vm._v("99弄9号8层卫心科技"),
                  ],
                  1
                ),
              ]),
              _vm._m(18),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("font", { attrs: { face: "微软雅黑" } }, [_vm._v("。")])],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c("span", {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "微软雅黑",
                  color: "rgb(0,0,0)",
                  "letter-spacing": "0.0000pt",
                  "text-transform": "none",
                  "font-style": "normal",
                  "font-size": "10.5000pt",
                  "mso-font-kerning": "0.0000pt",
                  background: "rgb(255,255,255)",
                  "mso-shading": "rgb(255,255,255)",
                },
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "p",
              staticStyle: {
                "margin-top": "0.0000pt",
                "margin-right": "0.0000pt",
                "margin-bottom": "0.0000pt",
                "margin-left": "0.0000pt",
                "text-indent": "21.0000pt",
                "mso-pagination": "widow-orphan",
                background: "rgb(255,255,255)",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "font-weight": "normal",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "10.5000pt",
                    "mso-font-kerning": "0.0000pt",
                    background: "rgb(255,255,255)",
                    "mso-shading": "rgb(255,255,255)",
                  },
                },
                [
                  _c("font", { attrs: { face: "微软雅黑" } }, [
                    _vm._v(
                      "一般情况下，我们将在五个工作日内回复，特殊情形下，最长将在不超过七天或法律法规规定期限内作出答复。"
                    ),
                  ]),
                ],
                1
              ),
              _c("b", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "mso-spacerun": "'yes'",
                      "font-family": "微软雅黑",
                      color: "rgb(0,0,0)",
                      "letter-spacing": "0.0000pt",
                      "font-weight": "bold",
                      "text-transform": "none",
                      "font-style": "normal",
                      "font-size": "10.5000pt",
                      "mso-font-kerning": "0.0000pt",
                      background: "rgb(255,255,255)",
                      "mso-shading": "rgb(255,255,255)",
                    },
                  },
                  [
                    _c("font", { attrs: { face: "微软雅黑" } }, [
                      _vm._v(
                        "如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您可以与我们再次沟通，或向有权机关反映。"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "微软雅黑",
                    color: "rgb(0,0,0)",
                    "letter-spacing": "0.0000pt",
                    "text-transform": "none",
                    "font-style": "normal",
                    "font-size": "13.5000pt",
                    "mso-font-kerning": "0.0000pt",
                  },
                },
                [_c("o:p")],
                1
              ),
            ]
          ),
          _c("p", { staticClass: "MsoNormal" }, [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "10.5000pt",
                  "mso-font-kerning": "1.0000pt",
                },
              },
              [_c("o:p")],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "微软雅黑",
          color: "rgb(0,0,0)",
          "letter-spacing": "0.0000pt",
          "font-weight": "bold",
          "text-transform": "none",
          "font-style": "normal",
          "font-size": "15.0000pt",
          "mso-font-kerning": "0.0000pt",
          background: "rgb(255,255,255)",
          "mso-shading": "rgb(255,255,255)",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "18.0000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_c("br")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "微软雅黑",
          color: "rgb(68,114,196)",
          "letter-spacing": "0.0000pt",
          "font-weight": "bold",
          "text-transform": "none",
          "font-style": "normal",
          "font-size": "15.0000pt",
          "mso-font-kerning": "0.0000pt",
          background: "rgb(255,255,255)",
          "mso-shading": "rgb(255,255,255)",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [
          _vm._v(
            "1、向儿童提供我们的服务。我们可能向合作伙伴及其他第三方共享儿童的信息，以实现PDBox平台服务的功能，让儿童正常使用需要的服务，例如：提供支付服务的支付机构、"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [
          _vm._v(
            "2、与关联方的必要共享。为便于我们基于统一的帐号体系向儿童提供一致化服务以及便于其进行统一管理、个性化推荐、保障系统和帐号安全等，儿童的个人信息可能会在我们和我们的关联方之间进行必要共享；"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("3、实现本政策")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [
          _vm._v(
            "4、履行我们在本政策或我们与儿童、监护人达成的其他协议中的义务和行使我们的权利；"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [
          _vm._v(
            "5、向委托我们进行推广的合作伙伴等第三方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别儿童个人身份的统计信息，帮助他们了解其受众或顾客；"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
            background: "rgb(255,255,255)",
            "mso-shading": "rgb(255,255,255)",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "微软雅黑",
            color: "rgb(0,0,0)",
            "letter-spacing": "0.0000pt",
            "font-weight": "bold",
            "text-transform": "none",
            "font-style": "normal",
            "font-size": "10.5000pt",
            "mso-font-kerning": "0.0000pt",
          },
        },
        [_vm._v(" 个人信息保护负责人（收）")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }